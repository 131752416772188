<template>
  <div class="bodydonate">
    <div class="body-container">
      <Worktrends :worklist="workinfolist" :Statistics="Statisticslist"></Worktrends>
      <Scintroduct :Scintroduct="Scintroduct"></Scintroduct>
      <Donatetoknow :donatetoknow="donatetoknow"></Donatetoknow>
      <Remembrance :remembrancelist="remembrancelist"></Remembrance>
      <Popular :getpopularlist="popularlist"></Popular>
    </div>
  </div>
</template>

<script>
import Worktrends from './components/worktrends'
import Scintroduct from './components/scintroduct'
import Donatetoknow from './components/donatetoknow'
import Remembrance from './components/remembrance'
import Popular from './components/popular'
import apiUrls from '@api'
export default {
  components: {
    Worktrends,
    Scintroduct,
    Donatetoknow,
    Remembrance,
    Popular
  },
  name: 'Bodydonate',
  data () {
    return {
      workinfolist: [],
      popularlist: [],
      remembrancelist : [],
      Statisticslist: {},
      Scintroduct : {},
      donatetoknow: {}
    }
  },
  created () {
    this.getworklist()
    this.getpopular()
    this.Remembrance()
    this.getbodydonate()
    this.Statistics()
    this.getStatistics() 
  },
  methods: {
    getworklist() {
      apiUrls.getbodydonate({node: 'ytqgjm_gzdt'}).then(res=>{
		  console.log(res.results.data)
      this.workinfolist= res.results.data
      })
    },
    getpopular() {
      apiUrls.getbodydonate({node: 'ytqgjm_kpzs'}).then(res=>{
      this.popularlist= res.results.data
      })
    },
    Remembrance() {
      apiUrls.getbodydonate({node: 'ytqgjm_mhjn'}).then(res=>{
      this.remembrancelist= res.results.data
      })
    },
    getStatistics () {
      apiUrls.getStatistics({node: 'ytqgjm_scsjcqkjj'}).then(res=>{
      this.Scintroduct= res.results.data
      })
    },
    getbodydonate () {
      apiUrls.getStatistics({node: 'ytqgjm_jxxz'}).then(res=>{
      this.donatetoknow = res.results.data
      })
    },
    Statistics() {
       apiUrls.getStatistics({node: 'ytqgjmjxtj'}).then(res=>{
      this.Statisticslist= res.results.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bodydonate {
  background-color: #f5f5f5;
  .body-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
  }
}
</style>
