<template>
  <div class="donatetoknow">
    <div class="donate-title">
    <span>捐献须知</span>
    </div>
    <div class="needknow-container">
        <h2 class="needknowtitle">{{donatetoknow.title || ""}}</h2>
        <TitleLine width="180px"></TitleLine>
        <div class="needknowcontent">
            <ul>
                <li v-for="(item) in 1" :key="item.id">
                    <!-- <dt>
                    <span>第一章</span>
                    <span>简介</span>
                    </dt> -->
                    <dd>
                    <p>{{donatetoknow.jysm || ""}}</p>
                    <!-- <p>第一条　为了规范人体器官移植，保证医疗质量，保障人体健康，维护公民的合法权益，制定本条例。</p> -->
                    <!-- <p>第二条　在中华人民共和国境内从事人体器官移植，适用本条例；从事人体细胞和角膜、骨髓等人体组织移植，不适用本条例。</p>
                    <p>本条例所称人体器官移植，是指摘取人体器官捐赠人具有特定功能的心脏、肺脏、肝脏、肾脏或者胰腺等器官的全部或者部分，将其植入接受人身体以代替其病损器官的过程。</p>
                    <p>第三条　任何组织或者个人不得以任何形式买卖人体器官，不得从事与买卖人体器官有关的活动。</p>
                    <p>第四条　国务院卫生主管部门负责全国人体器官移植的监督管理工作。县级以上地方人民政府卫生主管部门负责本行政区域人体器官移植的监督管理工作。</p>
                    <p>各级红十字会依法参与人体器官捐赠的宣传等工作。</p>
                    <p>
                       第五条　任何组织或者个人对违反本条例规定的行为，有权向卫生主管部门和其他有关部门举报；
                       对卫生主管部门和其他有关部门未依法履行监督管理职责的行为，有权向本级人民政府、上级人民政府有关部门举报。
                       接到举报的人民政府、卫生主管部门和其他有关部门
                    </p> -->
                    </dd>
                </li>
                <li v-for="(item) in 1" :key="item.id">
                    <!-- <dt>
                    <span>第二章</span>
                    <span>总刚</span>
                    </dt> -->
                    <dd>
                    <p v-html="donatetoknow.info?donatetoknow.info:''"></p>
                    </dd>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import TitleLine  from '@/components/TitleLine'
export default {
   props: {
     donatetoknow: {
         type: Object,
         default: {}
     } 
  },
  components: {
    TitleLine
  },
  name: 'Donatetoknow'
}
</script>

<style lang="less" scoped>
.donatetoknow {
    .donate-title  {
        margin-top: 53px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../assets/img/title.png') no-repeat center center;
                }
            }
        }
    }
    .needknow-container {
        width: 100%;
        // height: 633px;
        background: #ffffff;
        overflow: auto;       
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        .needknowtitle {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            margin: 40px 0 16px 0;
        }
        .titleborder {
            height: 6px;
            background: url('../../../assets/img/toknowborder.png') no-repeat center center;
        }
        .needknowcontent {
           width: 956px;
           // min-height: 340px; 
           margin: 35px 123px 75px 121px;
           ul {
               width: 100%;
               height: 100%;
               li {
                   margin-bottom: 35px;
                   dt {
                     width: 108px;
                     height: 17px;
                     display: flex;
                     justify-content:space-between;
                     font-size: 17px;
                     color: #D1292E;
                     font-weight: bold;
                     margin-bottom: 23px;
                   }
                   dd {
                       color: #666666;
                       line-height: 36px;
                       p {
                           text-indent: 2em;
                       }
                   }
                   &:last-child {
                       margin-bottom: 0;
                   }
               }
           }
        }
    }
}
</style>
