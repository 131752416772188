<template>
  <div class="worktrends">
    <div class="worktitle">
        <span>工作动态</span>
        <span @click="jumpToimgdetail">>>更多</span>
    </div>
    <div class="workcontent">
        <div class="workinfo">
            <div class="workbanners">
             <div class="block">
                <el-carousel trigger="click" height="327px">
                <el-carousel-item 
                v-for="item in imgList" 
                :key="item.id"
                @click.native="$goto('/detailShow',{id:item.id})"
                >
                    <!-- <h3 class="small">{{ item }}</h3> -->
                    <img style="width:100%; height:100%" :src="item.smallImage+'!327x327'" alt="">
                </el-carousel-item>
                </el-carousel>
            </div>
            <div class="mask"></div>
            </div>
            <div class="worklists">
                <ul>
									<template v-for="(item,index) in newsList" >
                    <li 
										v-if="index < 7"
                    :key="item.id"
                    @click="$goto('/detailShow',{id:item.id})"
                    >
                    <p><i></i><span>{{item.title}}</span></p>
                    <span>{{item.publishDate | formateData}}</span>
                    </li>
									</template>
                </ul>
            </div>
        </div>
        <div class="donatenum">
            <div class="donatetop">
                <div class="donatetitle">
                  —— 截至{{ Statistics.endDate}} ——
                </div>
            <div class="registernum">
                <h2>四川省器官遗体捐赠志愿登记（人份）</h2>
                <span>{{Statistics.volunteer_number}}</span>
            </div>
            <div class="realnum">
                <h2>实现捐赠(例)</h2>
                <div class="donatebody">
                    <div class="organ">
                        <h2>器官</h2>
                        <span>{{Statistics.organ}}</span>
                    </div>
                    <div class="remains">
                        <h2>遗体</h2>
                        <span>{{Statistics.remains}}</span>
                    </div>
                    <div class="remains">
                        <h2>角膜</h2>
                        <span>{{Statistics.corneal}}</span>
                    </div>
                </div>
            </div>
            </div>
            <div class="donatebottom" @click="$goto('/bodyRegister')"></div>    
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props : {
    worklist : {
        type: Array,
        default: []
    },
    Statistics : {
        type: Object,
        default: {}
    }
  },
  watch: {
    worklist() { // 接收父组件传来的参数名
      this.setData();
    }
  },
  name: 'Worktrends',
  data () {
      return {
          imgList:[],
          newsList:[]
      }
  },
  methods: {
      jumpToimgdetail () {
          this.$router.push({
          path: '/imgDetail',
          query: { name: "工作动态", type: 'ytqgjm_gzdt'}
        })
      },
      setData(){
        this.$nextTick(function() {
            let _this = this;
            this.worklist.forEach((item,ind) => {
							_this.newsList.push(item)
                if(_this.imgList.length<3&&item.smallImage){
                    _this.imgList.push(item)
                }
            })
        });
        
      }
  }
}
</script>

<style lang="less" scoped>
.worktrends {
    .worktitle  {
        cursor: pointer;
        width: 845px;
        margin-top: 10px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../assets/img/title.png') no-repeat center center;
                }
            }
            &:nth-child(2) {
                font-size: 17px;
                color: #ABABAB;
            }
        }

    }
    .workcontent {
        width: 100%;
        height: 360px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        .workinfo {
          width: 830px;
          height: 360px;
          background: #ffffff;        
          box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
          padding: 0 19px 0 15px;
          display: flex;
          .workbanners {
            position: relative;
            width: 344px;
            height: 327px;
            background-color: skyblue;
            margin-top: 18px;
            margin-right: 15px;
              .el-carousel__item h3 {
                    color: #475669;
                    font-size: 14px;
                    opacity: 0.75;
                    line-height: 150px;
                    margin: 0;
                }

                .el-carousel__item:nth-child(2n) {
                    background-color: #99a9bf;
                }
                
                .el-carousel__item:nth-child(2n+1) {
                    background-color: #d3dce6;
                }
                .mask {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 343px;
                    height: 40px;
                    z-index: 2;
                    // background: #000000;
                    opacity: 0.35;
                }
            }
          .worklists {
			  padding: 10px 0;
			  box-sizing: border-box;
            flex: 1;
            li {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
				padding: 10px 0;
				margin-top: 0 !important;
                p {
                    display: flex;
                    width: 392px; 
                    color: #333333;
                    span {
                        color: #000000;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                     i {
                    display: block;
                    width: 16px;
                    height: 16px;
                    background: url('../../../assets/img/list-little.png') no-repeat 0 center;
                   }
                }
                span {
                    color: #ABABAB;
					&:hover {
						color: #d1292e;
					}
                }
                &:first-child {
                    margin-top: 33px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
          }
        }
        .donatenum {
          width: 304px;
          height: 360px;
          background-color: #f5f5f5;
          .donatetop {
              width: 304px;
              height: 250px;
              background-color: #ffffff;
              overflow: hidden;            
              box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
          }
          .donatebottom {
              width: 304px;
              height: 91px;       
              box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
              margin-top: 19px;
              background: url("../../../assets/images/wydj.png") no-repeat center center;
          }
          .donatetitle {
              font-size: 14px;
              color: #666666;
              text-align: center;
              margin-top: 20px;
              margin-bottom: 8px;
              font-weight: bold;
          }
          .registernum {
              width: 279px;
              height: 86px;
              margin: 0 auto;
              margin-bottom: 8px;
              position: relative;
              background: url("../../../assets/images/组 20.png") no-repeat;
              background-size: 100% 100%;			  
              h2 {
                  font-size: 15px;
                  color: #333;
                  position: absolute;
                  font-weight: bold;
                  top: 8px;
                  left: 16px;
              } 
              span {
                  color: #F9696D;
                  font-size: 32px;
                  position: absolute;
                  font-weight: bold;
                  top: 38px;
				  left: 0;
				  right: 0;
                  margin: auto;
				  text-align: center;
                  letter-spacing: 13px;  
				 
              }
            }
            .realnum {
                width: 277px;
                height: 102px;
                border: 1px dashed #D5B9B9;
                border-radius: 8px;
                margin: 0 auto;
                h2 {
                    text-align: center;
                    color: #F95368;
                    font-size: 16px;
                    font-weight: bold;
                    margin: 7px 0 8px 0;
                }
                .donatebody {
                    height: 68px;
                    margin: 0 9px;
                    display: flex;
                    justify-content: space-between;
                    .organ,.remains {
                       width: 80px;
                       height: 68px;
                       background: url(../../../assets/images/底.png) no-repeat; 
					   background-size: 100% 100%;
                           text-align: center;
                       h2 {
                           color: #333;
                           margin-top: 15px;
                           text-align: center;
                       }
                       span {
                          color: #F9696D;
                          font-size: 18px;
                           margin-top: 5px;
                          font-weight: bold;
                       }
                       .remainnum {
                           text-indent: 23px;
                       }
                    }
                    /* .Corneal {
                        width: 95px;
                        height: 68px;
                        position: relative;
                        background: url('../../../assets/img/corneal.png') no-repeat center center;
                        h2 {
                            position: absolute;
                            top: 0px;
                            left: 32px;
                            color: #ffffff;
                        }
                        span {
                            position: absolute;
                            width: 100%;
                            text-align: right;
                            top: 35px;
                            left: 4px;
                            font-size: 24px;
                            font-weight: bold;
                            color: #F9696D;
                            letter-spacing: 11px;
                        }
                    } */
                }
            }
        }
    }
}
</style>
<style>
.el-carousel__button {
    border-radius: 50%;
    width: 8px !important;
    height: 8px !important;
}
.el-carousel__indicator {
    width: 15px !important;
}
.el-carousel__indicator--horizontal {
    /* padding: 14px 8px !important; */
    margin-right: 10px;
 } 
.is-active .el-carousel__button {
    width: 22px !important;
    border-radius: 15px;
    background-color: #D72829 !important;
}
</style>
