<template>
  <div class="scintroduct">
    <div class="sc-title" v-if="title">
      <span>{{title}}</span>
    </div>
    <div class="sc-content">
        <h2>{{Scintroduct.title || Subtitle}}</h2>
        <TitleLine width="220px"></TitleLine>
        <div class="introductcontent">
         <div class="intr-content">
            <p v-html="Scintroduct.text?Scintroduct.text:Scintroduct.info?Scintroduct.info:''">       
                <!-- 四川红十字应急救援救护中心（以下简称中心）成立于2010年，是省红会下属民办非企业单位。
                中心秉持“人道、博爱、奉献”的红十字精神，主要从事应急救护培训、应急救护普及、管理执行红十字救护项目等工作。
                在培训开展方面，一是红十字救护师资培训；二是红十字救护员培训；
                三是防灾减灾、应急避险、自救互救等相关知识宣传讲座。
                欢迎社会人士报名参加红十字应急救护培训，人人学急救，急救为人人。 -->
                
            </p>
         </div>
         <div class="bg-left"></div>
        </div>
    </div>
  </div>
</template>

<script>
import TitleLine  from '@/components/TitleLine'
export default {
  props: {
    Scintroduct: {
        type: Object,
        default: () => {}
    },
    title:{
        type:String,
        default:"四川省基本情况简介"
    },
    Subtitle:{
        type:String,
        default:""
    }
  },
  components: {
    TitleLine
  },
  name: 'Scintroduct'
}
</script>

<style lang="less" scoped>
.scintroduct {
    .sc-title  {
        width: 845px;
        margin-top: 53px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../assets/img/title.png') no-repeat center center;
                }
            }
        }
    }
    .sc-content {
        width: 100%;
        min-height: 380px;
        background-color: #ffffff;
        overflow: hidden;    
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        h2 {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            margin: 40px 0 16px 0;
        }
        .titleborder {
            height: 6px;
            background: url('../../../assets/img/sc-border1.png') no-repeat center center;
        }
        .introductcontent {
            width: 1038px;
            min-height: 200px;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0px 0px 15px 1px rgba(215, 22, 13, 0.26);
            margin: 37px 81px 57px 81px;
            .intr-content {
                width: 957px;
                min-height: 125px;
                margin: 38px 41px 37px 40px;
                p {
                    text-indent: 2em;
                    line-height: 36px;
                    color: #666666;
                }
            }
            .bg-left {
                width: 185px;
                height: 222px;
                position: absolute;
                top: 0;
                left: 0;
                background: url('../../../assets/img/bg-left.png') no-repeat center center;
                background-size: 100% 100%;
            }
        }
    }
}
</style>
